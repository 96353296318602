import React, { useEffect, useState } from "react";
import "../component/ChallengeReceived.css";
import axios from "axios";
import { Link, useNavigate,useParams } from "react-router-dom";
import { Loader } from "../component/Loader";
import { postimage } from "../actions/apiAction";
import { useDispatch, useSelector } from "react-redux";
import "../Pages/tickimage.css";
import { errorToast } from "../constants/ToastNotifications";
import { Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ContentLoader from "react-content-loader";
import vs from "../images/VS icon.png";
import { Avatar } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from "moment-timezone";
import Overwolf_App_Download from '../images/App_download_Real_final.svg';
export const PublicRecieve = () => {
  const navigate = useNavigate();
  // const alert = useAlert()
  const dispatch = useDispatch();
  const { image, loading, isImage } = useSelector((state) => state.image);
  const storagedata = JSON.parse(localStorage.getItem("nftuser"));

  const [selectedimage, setselectedimage] = useState([]);
  const [playertwoname, setplayertwoname] = useState("");
  const [challengedata, setChallenge] = useState([]);
  const [playeronename, setplayeronename] = useState("");
  const [loader, setLoader] = useState(false);
  const [acceptloader, setAcceptLoader] = useState(false);
  // const [show, setShow] = useState(false);
  const [playeroneid, setplayeroneId] = useState("");
  const [playertwoid, setplayertwoId] = useState("");
  const [userimagedata, setuserimagedata] = useState([]);
  const [checkedimage, setcheckedimage] = useState([]);
  const [erromessage, setErrorMessage] = useState("");
  const [challengerid, setChallengerId] = useState("");
  const [winning, setwinning] = useState("");
  const [losing, setlosing] = useState("");
  const [PlayerAvailableFund, setPlayerAvailableFund] = useState("");
  const [text, setText] = useState("1v1: Best of 3 Games");
  const [gamechoice, setGameChoice] = useState("Rocket League");
  const [gamenetwork, setGameNetwork] = useState("Steam");
  const [playerwager, setPlayerWager] = useState("");
  const [linkurl, setlinkurl] = useState("");
  const [linkreq, setlinkReq] = useState(false);
  const [showmodal, setShowModal] = useState(false);
  const [moneyValue, setMoneyValue] = useState("");
  const [tabLoading, setTabLoading] = useState(false);
  const [currentUserdata, setcurrentUserdata] = useState([]);
  const [serviceTax, setServiceTax] = useState(0);
  const [totalwager, setTotalwager] = useState(0);
  const [epicusername, setEpicusername] = useState("");
  const [xboxusername, setXboxusername] = useState("");
  const [steamusername, setSteamusername] = useState("");
  const [playstationusername, setPlaystationusername] = useState("");
  const [isTicked, setIsTicked] = useState(true);
  const [requiredChecked, setRequiredChecked] = useState(false);
  const [showmodal1, setShowModal1] = useState(false);
  const [overwolfConnected, setOverwolfConnected] = useState(false);
  const [challid,setChallId]=useState("");
  const [acceptChallengeLoader, setAcceptChallengeLoader] = useState(false);
  const [continueChallengeLoader, setContinueChallengeLoader] = useState(false);
  const [accept,setAccept]=useState();
  const [overwolfConnectedMsg, setOverwolfConnectedMsg] = useState(false);
  const [playeroneemail, setplayeroneEmail] = useState("");
  const [playertwoemail, setplayertwoEmail] = useState("");
  const [playerepicusername,setPlayerEpicusername] = useState("");
  const {id} = useParams()
  const handleClose = () => {
    setShowModal(false);
  };

  // const handleShow = () => setShow(true);
  const Getcurrentuser = async () => {
    const userres = await axios.post("/api/auth/getcurrentuser", {
      id: storagedata._id,
    });
    setcurrentUserdata(userres?.data);
    setPlayerAvailableFund(userres?.data?.available_fund);
    setOverwolfConnected(userres?.data?.overwolf);
    setplayertwoEmail(userres?.data?.email);
    setplayertwoname(userres?.data?.username);
    setPlayerEpicusername(userres?.data?.userepicname);
  };

  const popupHandler = (event) => {
    setShowModal1(false);
  }

  const handleOutsideClick = (event) => {
    if (event.target.classList.contains("modal")) {
      closeModal();
    }
  };

  const closeModalHandler = async(index) => {
    setOverwolfConnectedMsg(false);
    setShowModal1(false);
  };

  const handleRequiredChange = (event) => {
    setRequiredChecked(event.target.checked);
  };
  const getPublicChallengeData = async () => {
    setTabLoading(true);
    const newres = await axios.post("/api/auth/publicchallengedatabyid", {
      challengeid: id,
    });
    if (newres) {
      console.log("newres:", newres);
      let tempdata = []
      tempdata.push(newres?.data)
      setChallenge(tempdata);
      setplayeronename(newres?.data?.player_1[0]?.name);
      setplayeroneId(newres?.data?.player_1_id);   
      setGameChoice(newres?.data?.player_1[0]?.gamechoice);
      setGameNetwork(newres?.data?.player_1[0]?.gamenetwork);
      setText(newres?.data?.player_1[0]?.text);
      setPlayerWager(newres?.data?.playerwager);
      setEpicusername(newres?.data?.player_1[0]?.userId?.userepicname);
      setXboxusername(newres?.data?.player_1[0]?.userId?.userxboxname);
      setPlaystationusername(newres?.data?.player_1[0]?.userId?.userplaystationname);
      setSteamusername(newres?.data?.player_1[0]?.userId?.usersteamname);
      setlinkReq(newres?.data?.linkreq);
      setServiceTax(newres?.data?.servicecharges)
      setTotalwager(newres?.data?.totalwager)
      setChallengerId(newres?.data?._id);
      setplayeroneEmail(newres?.data?.player_1[0]?.email);
      setTabLoading(false);
    }
  }
  // const getallchallenge = async () => {
  //   setTabLoading(true);
  //   const res = await axios.get("/api/auth/getallchallenge");
  //   console.log(res, "public receive data");
  //   if (res) {
  //     setChallenge(res.data.filter((items, index) => {
  //     setChallengerId(items?._id);
  //     setplayeronename(items?.player_1[0]?.name);
  //     setplayeroneId(items.player_1_id);   
  //     setGameChoice(items?.player_1[0]?.gamechoice);
  //     setGameNetwork(items?.player_1[0]?.gamenetwork);
  //     setText(items?.player_1[0]?.text);
  //     setPlayerWager(items?.player_1[0]?.playerwager);
  //     setEpicusername(items?.player_1[0]?.userId?.userepicname);
  //     setXboxusername(items?.player_1[0]?.userId?.userxboxname);
  //     setPlaystationusername(items?.player_1[0]?.userId?.userplaystationname);
  //     setSteamusername(items?.player_1[0]?.userId?.usersteamname);
  //     // setPlayerAvailableFund(currentUserdata?.available_fund);
  //     setlinkReq(items?.linkreq);
  //     setServiceTax(items?.servicecharges)
  //     setTotalwager(items?.totalwager)
  //     return items?._id === id && items?.Accept === "pending"
  //   }
  //   ));
  // }
  //   setTabLoading(false);
  // };
  const handleInputChange = (event) => {
    setMoneyValue(event.target.value);
  };

  const addFund = async (req) => {
    if (moneyValue == "" || moneyValue <= 0) {
      errorToast("Please enter some valid value for add fund");
      return;
    }
    const res = await axios.post("/api/auth/depositmoney", {
      price: moneyValue,
      userid: storagedata._id,
    });
    if (res) {
      setShowModal(false);
      location.reload();
    }
  };
  const AcceptChallenge=async(index)=>{
    if (PlayerAvailableFund < playerwager) {
      setErrorMessage("Insufficient balance! Recharge to participate in this game challenge.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2200);
      return;
    }
    
    if(linkreq || linkurl){   

    // if (gamechoice.length <= 0) {
    //   setErrorMessage("Please fill the game of choice");
    //   setTimeout(() => {
    //     setErrorMessage("");
    //   }, 2200);
    //   return;
    // } else if (gamenetwork.length <= 0) {
    //   setErrorMessage("Please fill the game network");
    //   setTimeout(() => {
    //     setErrorMessage("");
    //   }, 2200);
    //   return;
    // } else if (text.length <= 0) {
    //   setErrorMessage("Please fill the terms");
    //   setTimeout(() => {
    //     setErrorMessage("");
    //   }, 2200);
    //   return;
    // } else
     if (PlayerAvailableFund < playerwager) {
      setErrorMessage("Insufficient balance! Recharge to participate in this game challenge.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2200);
      return;
    } else if (linkreq && linkurl == "") {
      setErrorMessage("Please enter stream link on respective field.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2200);
      return;
    } else if (gamenetwork == "Epic Games" ){   
      console.log("epicusername",playerepicusername,gamenetwork)
        if(!playerepicusername || playerepicusername == ""){
          setErrorMessage("To accept for this challenge, users must enter their Epic username. Go to your profile and add your Epic username.")
      setTimeout(() => {
        setErrorMessage("");
      }, 2200);
      return;
    }
  }
  console.log("here");
    setAcceptLoader(true);
    setAcceptChallengeLoader(true);
    const res = await axios.put("/api/auth/setplayertwo",{
      challengerid:challengedata[index]?._id,
      Accept:true,
      Accepttime: new Date(),
      playertwoid:storagedata._id,
      playeroneid:playeroneid,
      decline:false,
      playertwolink:linkurl,
      name:storagedata.username,
      gamechoice:gamechoice,
      gamenetwork: gamenetwork,
      text:text,
      playerwager: challengedata[index]?.playerwager,
      totalwager: totalwager,
      challengenumber : challengedata[index]?.challenge_number,
      playeroneemail:playeroneemail,
      playeronename:playeronename,
      playertwoemail:playertwoemail,
      playertwoname:playertwoname
    })
    setChallId(challengedata[index]?._id);
    setAcceptChallengeLoader(false);
    navigate(`/${"DuelAccepted"}/${challengedata[index]?._id}`);
  }
     else{
      setShowModal1(true);
      setAcceptChallengeLoader(false);
      setChallId(challengedata[index]._id);
     }
    
  }
  const ContinueChallenge = async (index,e) => {
    if (!requiredChecked) {
      // errorToast("Please give the acknowledgement");
      return;
    }
    e.preventDefault();
    if (!overwolfConnected && (gamenetwork === "Steam" || "Epic Games")) {
      setOverwolfConnectedMsg(true);
      return;
    }
    // if (gamechoice.length <= 0) {
    //   errorToast("Please fill the game of choice");
    //   setTimeout(() => {
    //     setErrorMessage("");
    //   }, 2200);
    //   return;
    // } else if (!gamenetwork || gamenetwork.length <= 0) {
    //   errorToast("Please fill the game network");
    //   setTimeout(() => {
    //     setErrorMessage("");
    //   }, 2200);
    //   return;
    // } else 
    // if (text.length <= 0) {
    //   errorToast("Please fill the terms");
    //   setTimeout(() => {
    //     setErrorMessage("");
    //   }, 2200);
    //   return;
    // } else
     if (PlayerAvailableFund < playerwager) {
      setErrorMessage("Insufficient balance! Recharge to participate in this game challenge.");
      setTimeout(() => {
        setErrorMessage("");
      }, 2200);
      return;
    } else if (linkreq && linkurl == "") {
      setErrorMessage("Please enter a link");
      setTimeout(() => {
        setErrorMessage("");
      }, 2200);
      return;
    }

    setAcceptLoader(true);
    setContinueChallengeLoader(true);
    const res = await axios.put("/api/auth/setplayertwo",{
      challengerid:challengedata[index]?._id,
      Accept:true,
      Accepttime: new Date(),
      playertwoid:storagedata._id,
      playeroneid:playeroneid,
      decline:false,
      playertwolink:linkurl,
      name:storagedata.username,
      gamechoice:gamechoice,
      gamenetwork: gamenetwork,
      text:text,
      playerwager: challengedata[index]?.playerwager,
      totalwager: totalwager,
      challengenumber : challengedata[index]?.challenge_number,
      playeroneemail:playeroneemail,
      playeronename:playeronename,
      playertwoemail:playertwoemail,
      playertwoname:playertwoname
    })
    setChallId(challengedata[index]?._id);
    navigate(`/${"DuelAccepted"}/${challengedata[index]?._id}`);
    setContinueChallengeLoader(false);
    setShowModal(false);
  };

  const countwinlose = async () => {
    const res = await axios.post("/api/auth/countwinlose", {
      user: storagedata._id,
    });
    let winfiltered = res.data.filter((items, index) => {
      return items.winner === storagedata._id;
    });
    setwinning(winfiltered.length);
    setlosing(res.data.length - winfiltered.length);
  };

  const handleModalShow = async () => {
    setShowModal(true);
  };
  useEffect(() => {
    Getcurrentuser();
    // getallchallenge();
    getPublicChallengeData();
    countwinlose();
  }, []);
  return (
    <>
      <div>
        <div className="container">
          <div className="inner-space-wrap"> 
            <div className="section-title">
              <h2 className="duel-receive-body-main">Challenge Received</h2>
            </div>
            {erromessage && (
              <div className="popup error text-center web-view" style={{ margin: 0 ,color: "white"}}>
                <div className="message">
                  <p>{erromessage}</p>
                </div>
              </div>
            )}
            {tabLoading? <div className="row align-items-center justify-content-center mt-5 mb-5">
                <React.Fragment>
                  <div className="col-md-4 col-sm-12 text-center mb-3 mb-md-0">
                    <div className="challenge-info">
                      <div className="duel-left ">
                        <div className="cur_user-info">
                          <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 200 55"
                          />
                        </div>
                        <div className="user-details">
                          <ContentLoader
                            height={60}
                            speed={1}
                            backgroundColor={"#333"}
                            foregroundColor={"#999"}
                            viewBox="0 0 130 55"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 text-center mb-3 mb-md-0">
                    <div className="duel-center">
                      <img src={vs} alt="img" className="vs-logo" />
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12 text-center">
                    <div className="duel-right">
                      <div className="opp_user-info">
                        <div className="details">
                        <div className="cur_user-status">
                            <div className="details-left">
                              <ContentLoader
                                height={60}
                                speed={1}
                                backgroundColor={"#333"}
                                foregroundColor={"#999"}
                                viewBox="0 0 150 55"
                              />
                              <ContentLoader
                                height={60}
                                speed={1}
                                backgroundColor={"#333"}
                                foregroundColor={"#999"}
                                viewBox="0 0 150 55"
                              />
                            </div>
                            <div className="details-right">
                              <ContentLoader
                                height={60}
                                speed={1}
                                backgroundColor={"#333"}
                                foregroundColor={"#999"}
                                viewBox="0 0 150 55"
                              />
                              <ContentLoader
                                height={60}
                                speed={1}
                                backgroundColor={"#333"}
                                foregroundColor={"#999"}
                                viewBox="0 0 150 55"
                              />
                        </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div> : challengedata?.length > 0 ? (
              <>
                {challengedata.map((items, index) => {
                  return (
                    <div className="row align-items-center justify-content-center mt-5 mb-5">
                      <React.Fragment key={items._id}>
                        <div className="col-md-4 col-sm-12 text-center mb-3 mb-md-0">
                          {/* <div className="challenge-info"> */}
                          <div className="duel-left ">
                          <div className="challenge-number-left">
                                <span className="title">Challenge Date: </span>
                                <span className="value">
                                {items?.challenge_created ?<>{moment(items?.challenge_created).tz(moment.tz.guess()).format("MM/DD/YYYY h:mm A")}</> : <>---</> }
                                </span>
                              </div>
                          <div className="mb-2 challenge-number-left">
                                <span className="title">Challenge No.: </span>
                                <span className="value">
                                  {items?.challenge_number}
                                </span>
                              </div>
                            <div className="cur_user-info">
                              <Avatar
                                src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${items?.player_1[0]?.userId?.avatar}`}
                                
                                className="player-avatar"
                              />
                              <div className="cur_user-name">
                                {items.player_1[0].name}
                              </div>
                            </div>
                            <div className="cur_user-status">
                              <div className="challenge-status duel-received-win">
                                <h4>W</h4>
                                <h4>{items.player_1[0]?.userId?.winning}</h4>
                              </div>
                              <div className="challenge-status lost">
                                <h4>L</h4>
                                <h4>{items.player_1[0]?.userId?.losing}</h4>
                              </div>
                            </div>

                            <div className="user-details">
                              <div className="wager">Wager - </div>
                              <div className="money">
                                ${items.playerwager?.toFixed(2)}
                              </div>
                              <div className="challenge-content">
                                <span className="title">TERMS:</span>
                                <span className="value">
                                  {items.player_1[0].text}
                                </span>
                              </div>
                              <div className="challenge-content">
                                <span className="title">Game:</span>
                                <span className="value">
                                  {items.player_1[0].gamechoice}
                                </span>
                              </div>
                              <div className="challenge-content">
                                <span className="title">Network:</span>
                                <span className="value">
                                  {items?.player_1[0]?.gamenetwork}
                                </span>
                              </div>
                              <div className="cam-scan">
                                <span className="title">Require Livestream?</span>
                                <div className="tickbolck">
                                  <div
                                    className={
                                      items.linkreq ? "clicked" : "unclicked"
                                    }
                                  >
                                    <button type="button">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59L21 7Z"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                            {gamenetwork === "Steam" && steamusername && (
                              <>
                                <span className="title">Steam Username:</span>
                                <span className="value epic">{steamusername}</span>
                              </>
                            )}
                            {gamenetwork === "Playstation Network" && playstationusername && (
                              <>
                                <span className="title">Playstation Username:</span>
                                <span className="value epic">{playstationusername}</span>
                              </>
                            )}
                            {gamenetwork === "Xbox Live" && xboxusername && (
                              <>
                                <span className="title">Xbox Username:</span>
                                <span className="value epic">{xboxusername}</span>
                              </>
                            )}
                            {gamenetwork === "Epic Games" && epicusername && (
                              <>
                                <span className="title">Epic Username:</span>
                                <span className="value epic">{epicusername}</span>
                              </>
                            )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-12 text-center mb-3 mb-md-0">
                          <div className="duel-center">
                            <img src={vs} alt="img" className="vs-logo" />
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12 text-center">
                          <div className="duel-right">
                            <div className="opp_user-info">
                              <div className="details">
                                <div className="cur_user-status">
                                  <div className="details-left">
                                    <div className="wager">
                                      Your wager to enter -
                                    </div>
                                    <div className="money">
                                      ${items.playerwager?.toFixed(2)}
                                      {/* <div className="tax">+ (Service Charge = ${serviceTax || 0})</div>
                                      <div className="total-cost">Total charge = ${items.player_2[0].totalwager}</div> */}
                                    </div>
                                    <div className="cur-fund mt-1">
                                      Available Funds: $
                                      {currentUserdata?.available_fund?.toFixed(2)}
                                    </div>
                                    <div className="deposit-btn mt-4">
                                    <Link to="/profile/4">
                                      <button
                                        type="button"
                                      >
                                        Deposit Funds
                                      </button>
                                      </Link>
                                    </div>
                                    <div className="win-status m-3 pt-3">
                                      <div className="winner-details">
                                        Winner Receives
                                      </div>
                                      <div className="money-won">
                                        ${(items.playerwager * 2)?.toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="details-right">
                                  <div className="user-history">
                                    <div className="cur_user-info">
                                      <Avatar
                                        src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${currentUserdata?.avatar}`}
                                       
                                        className="right-player-avatar"
                                      />
                                      <div className="cur_user-name">
                                        {items.player_2[0].name}
                                      </div>
                                    </div>
                                    <div className="opp_challenge-status duel-received-win">
                                      <h6>W</h6>
                                      <h6>
                                        {currentUserdata?.winning}
                                      </h6>
                                    </div>
                                    <div className="opp_challenge-status lost">
                                      <h6>L</h6>
                                      <h6>{currentUserdata?.losing}</h6>
                                    </div>
                                  </div>

                                  <div className="challenge-content">
                                    <div className="mb-3 mt-3">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        style={{ color: "#C1C1C1" }}
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm0-2h12V10H6v10Zm6-3q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2ZM6 20V10v10Z"
                                        />
                                      </svg>
                                      <span className="title">TERMS: </span>
                                      <span className="value">
                                        {items.player_1[0].text}
                                      </span>
                                    </div>
                                    <div className="mb-3">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        style={{ color: "#C1C1C1" }}
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm0-2h12V10H6v10Zm6-3q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2ZM6 20V10v10Z"
                                        />
                                      </svg>
                                      <span className="title">GAME: </span>
                                      <span className="value">
                                        {items.player_1[0].gamechoice}
                                      </span>
                                    </div>
                                    <div className="mb-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        style={{ color: "#C1C1C1" }}
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm0-2h12V10H6v10Zm6-3q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2ZM6 20V10v10Z"
                                        />
                                      </svg>
                                      <span className="title">NETWORK: </span>
                                      <span className="value">
                                        {items.player_1[0].gamenetwork}
                                      </span>
                                    </div>
                                    <div className="mb-3">
                                      <input
                                        type="url"
                                        name="accept-platform-url"
                                        className="platform-url"
                                        placeholder="Please enter stream link on respective field."
                                        onChange={(e) =>
                                          setlinkurl(e.target.value)
                                        }
                                      />
                                      {items.linkreq ? (
                                        <span className="required-field">
                                          *
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="challange-actions mt-4">
                                      <button
                                        className="mb-2 chal-acc-btn" 
                                        onClick={() => AcceptChallenge(index)}
                                        disabled={acceptChallengeLoader}
                                      >
                                       {acceptChallengeLoader ? <Spinner animation="border" size="sm"/>: "ACCEPT CHALLENGE"}
                                      </button>
                                      {/* <button onClick={DeclineChallenge}>
                                        DECLINE CHALLENGE
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {showmodal1  &&<div className="modal challenge-someone-modal" onClick={handleOutsideClick}>
                     <div className="modal-content challengesomeone-modal-content">
                      <form>
                     <Modal.Header className="challengesomeone-header">
                     <button onClick={()=>closeModalHandler(index)} className="modal-button challengesomeone-cancel-btn">&times;</button>
                     <Modal.Title className="modal-header-font">– Notice –</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                       <p>By not requiring a live stream link from your opponent, both participants will be required to download and run the <b>GameWagrs Validator app</b> on the Overwolf Appstore. This will ensure each tournament, or "challenge", is verified by the game results received by the GameWagrs Validator app and the winner is guaranteed their payout.</p>
                       <p>In addition to downloading and running the <b>GameWagrs Validator app</b>, each participant is required to link their active Overwolf account username by navigating to your <Link to="/profile" onClick={popupHandler}><u>Profile</u></Link> <i className='fas fa-arrow-right'></i> Linked Accounts <i className='fas fa-arrow-right'></i> Connect Overwolf Account</p>
                       <p>Overwolf Account Status: {overwolfConnected ? <span className="win">Successful</span> : <span className="lost">Failed</span>}</p>
                       <p className="fs-18"><u>**This app must be running after your challenge is accepted by your opponent</u></p>
                       <p><input className="required-tick" type="checkbox" required onChange={handleRequiredChange} checked={requiredChecked} />&nbsp;<span style={{color: "red"}}>*</span>&nbsp;I acknowledge and accept the fact I am required to have the GameWagrs Validator app running while I participate in any live challenge</p>
                       <img src="/popup.gif" className="popup-gif"/>
                       <p>Go to <Link to="/profile"><u>Profile</u></Link>&nbsp;|&nbsp;<Link to="/contact-us"><u>Contact Support</u></Link></p>
                       {overwolfConnectedMsg?<p className="error-message">In order to send challenges, users must link their active Overwolf account username in the profile section</p>:null} 
                       <p className="ow-app-download-icon"> <a href="https://www.overwolf.com/app/Gamewagrs.com-GameWagrs" target="_blank">
                  <img alt="logo" className="image" width={"180px"} src={Overwolf_App_Download} /> 
                  </a></p>
                       </Modal.Body>
                       <Modal.Footer>
                       <button onClick={ (e) => ContinueChallenge(index,e)} disabled={continueChallengeLoader} className="chal-cont-btn">
                       {continueChallengeLoader ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <>
                            Continue&nbsp;
                            <FontAwesomeIcon icon={faArrowRight} />
                          </>
                        )}</button>
                       </Modal.Footer>
                       </form>
                     </div>
                   </div>}
                      </React.Fragment>
                    </div>
                   );
                  })}
                  {erromessage && (
              <div className="popup error text-center mobile-view" style={{ margin: 0 ,color: "white"}}>
                <div className="message">
                  <p>{erromessage}</p>
                </div>
              </div>
            )}
                </>
              ) : null}
              { challengedata?.length < 1 && !tabLoading ? <div className="main-container">
                <div className="no-duel-received">
                  <div className="no-duel-text">
                    <h1>You haven't received any challenges yet </h1>
                    <p>You can challenge someone</p>
                    <div className="thankbtn">
                      <Link to="/ChallengeSomeone">
                        <button className="go-home hero-btn">
                          ChallengeSomeone
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>: null}
          </div>
        </div>
      </div>
      <Modal show={showmodal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            value={moneyValue}
            onChange={handleInputChange}
            type="number"
          ></input>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addFund}>
            add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
