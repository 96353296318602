import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
//import fulllogo_transparent from "../images/fulllogo_transparent.png";
//import Logo from '../images/Logo.png';
import fulllogo_transparent_figma from '../images/fulllogo_transparent_figma.png';
import Badge from "@material-ui/core/Badge";
import Notifications from "@material-ui/icons/Notifications";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Divider } from "@material-ui/core";
import SocialMediaLinks from "./SocialMediaLinks";
import { loaduser, logout } from "../actions/userAction";
import "react-toastify/dist/ReactToastify.css";
import NotificationSkeleton from "../skeletons/NotificationSkeleton";
import { LOGOUT_USER_SUCCESS } from "../constants/userConstants";
import Overwolf_App_Download from '../images/newdownloadbutton.svg';
const Header = () => {
  const { isAuthenticated, user, error, loading } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const [colorChange, setColorchange] = useState(false);
  const [notification, setNotification] = useState([]);
  const [publicchallenge, setPublicChallenge] = useState([]);
  const [count, setCount] = useState();
  const [toggle, setToggle] = useState(false);
  const [allnotification, setallnotification] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const data = JSON.parse(localStorage.getItem("nftuser"));
const dispatch = useDispatch();
  const toggleCanvas = () => {
    setShow((show) => !show);
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const updatenotification = async () => {
    await axios.put("/api/auth/updatenotification", {
      id: data._id,
      arr: [data._id],
    });
  };

  window.addEventListener("scroll", changeNavbarColor);
  const getnotification = async () => {
    try {
      const res = await axios.post("/api/auth/getusernotification", {
        id: data?._id,
        arr: [data?._id],
      });

      if (res.data) {
        setallnotification(res.data.allnotifications);
        setNotification(res.data.notificationlist);
        
          res.data.notificationlist.filter((items, index) => {
            if(items.type === "winlose"){
              dispatch(loaduser());
              console.log(items.type, "win/lose")
            }
            if(items.type === "Declined"){
              dispatch(loaduser());
              console.log(items.type, "decliiiiiiiiined")
            }
            console.log(items.type, "notification type");
          })
        
        setCount(res.data.notificationcount);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  };

  const ToggleClass = () => {
    setToggle(!toggle);
  };

  const Messagedisplay = async () => {
    const note = notification.map((items, index) => {
      toastnotification(
        <div style={{ color: "white" }}>{items.messages}</div>,
        items._id
      );
    });
    if (notification.length) setNotification([]);
  };

  const removeNotification = async (notificationId, path) => {
    setallnotification(
      allnotification?.filter((i) => i?._id !== notificationId)
    );
    await axios.post("api/auth/markNotificationRead", { _id: notificationId });
    navigate(path);
  };
  
  // console.log("<--- User Notification --->", allnotification);
  const toastnotification = async (notes, id) => {
    toast.info(notes, {
      position: "top-right",
      autoClose: 5000,
      toastId: id,
      hideProgressBar: false,
      onClose: async () => await updatenotification(),
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleLoginRequiredLink = (e) => {
    if(JSON.parse(localStorage.getItem("nftuser"))?._id==null){
      e.preventDefault();
      navigate("/login");
    }
  }

  useEffect(() => {
    Messagedisplay();
  }, [notification, count, isAuthenticated]);

  useEffect(() => {
   const timer = setTimeout(() => {
      if (data && data._id) {
        getnotification();
      }
    }, 15000);
    return () => {
      clearTimeout(timer);
    };
  }, [notification, localStorage.getItem("nftuser")]);

  // useEffect(() => {
  //   if (data && data._id) {
  //     getnotification();
  //   }
  // }, []);

  const logoutuser = () => {
    dispatch(logout());
    setallnotification([]);
    localStorage.removeItem("nftuser");
    localStorage.removeItem("overwolfuser");
    navigate("/login");
  };

  // const newNotifications = `You have ${count} new notifications!`;
  // const noNotifications = `No new notifications`;

  return (
    <div className={colorChange ? "navbar colorChange" : "navbar"}>
      <div className="container-fluid topheader desktop-nav fixed-top">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <ToastContainer />
            {/* <div className="container-fluid"> */}
              <Link className="" to="/">
              <img loading="lazy" alt="logo" className="image" src={fulllogo_transparent_figma} /> 
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse header-content"
                id="navbarSupportedContent"
              >
                <div className="header-menu-list">
                  <ul
                    className="navbar-nav mb-2 mb-lg-0 nav-menu"
                    style={{ marginRight: "2.6%", paddingRight: "15px" }}
                  >
                    {/* Solo Showdown */}
                    <li className="nav-item dropdown hover">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="navbarScrollingDropdown2"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Solo Showdown
                      </Link>
                      <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                        <li>
                          <Link to="/soloshowdown" className="dropdown-item">
                            Start Challenge
                          </Link>
                        </li>
                        <li>
                          <Link to="/SoloShowDownStatus" className="dropdown-item" onClick={handleLoginRequiredLink}>
                            View History
                          </Link>
                        </li>
                      </ul>
                    </li>

                    {/* Challenge Someone */}
                    <li className="nav-item dropdown hover">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="navbarScrollingDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Challenge Someone
                      </Link>
                      <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                        <li>
                          <Link to="/ChallengeSomeone" className="dropdown-item">
                            Send Challenge
                          </Link>
                        </li>
                        <li>
                          <Link to="/ChallengeReceived" className="dropdown-item" onClick={handleLoginRequiredLink}>
                            Received Challenges
                          </Link>
                        </li>
                        <li>
                          <Link to="/DuelStatus" className="dropdown-item" onClick={handleLoginRequiredLink}>
                            View History
                          </Link>
                        </li>
                      </ul>
                    </li>

                    {/* Challenge Marketplace */}
                    <li className="nav-item">
                      <Link
                        to="/challengemarketplace"
                        className="nav-link"
                        id="navbarScrollingDropdown"
                        style={{ marginLeft: "10px" }}
                        aria-expanded="false"
                      >
                        Challenge Marketplace
                      </Link>
                    </li>

                    {/* How To Play */}
                    <li className="nav-item">
                      <Link to="howtoplay" className="nav-link" aria-current="page">
                        How To Play
                      </Link>
                    </li>

                    {/* FAQ */}
                    <li className="nav-item" style={{ whiteSpace: "nowrap" }}>
                      <Link to="faqs" className="nav-link" aria-current="page">
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </div>
                
                <div className="header-right">
               
                <form
                  style={{ position: "relative" }}
                  className="d-flex userprofile"
                >
                  {localStorage.getItem("nftuser") ? (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                      }}
                    >
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle nav-drop display p-0"
                          to="/AboutRules"
                          id="navbarScrollingDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div
                            className="nav-item dropdown"
                            style={{ color: "#8d8ddf", position: "relative" }}
                          >
                            <Badge
                              overlap="circular"
                              badgeContent={count}
                              color="primary"
                            >
                              <Notifications
                                style={{
                                  cursor: "pointer",
                                  fontSize: "2rem",
                                }}
                              />
                            </Badge>
                          </div>
                        </Link>
                        <div
                          className="dropdown-menu notification-wrapper"
                          aria-labelledby="navbarScrollingDropdown"
                        >
                          <ul className="notification">
                            {isLoading && (
                              <div className="d-flex justify-content-center">
                                <NotificationSkeleton />
                              </div>
                            )}
                            {!isLoading && allnotification.length === 0 ? (
                              <>
                                <li
                                  className="notifications no-hover"
                                  style={{
                                    fontSize: "12.5px",
                                    color: "#fff",
                                  }}
                                >
                                  No new notifications
                                </li>
                              </>
                            ) : (
                              <>
                                {!isLoading &&
                                  allnotification?.length > 0 &&
                                  allnotification.map((items, index) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {items.type === "recieved" ? (
                                          <li className="notifications">
                                            <i className="fa-solid fa-user-check"></i>
                                            <Link
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeNotification(
                                                  items._id,
                                                  "/ChallengeReceived"
                                                );
                                              }}
                                            >
                                              {items.messages}
                                            </Link>
                                          </li>
                                        ) : items.type === "Accepted" ? (
                                          <li className="notifications">
                                            <i className="fa-solid fa-flag-checkered"></i>
                                            <Link
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeNotification(
                                                  items._id,
                                                  "/DuelAccepted"
                                                );
                                              }}
                                            >
                                              {items.messages}
                                            </Link>
                                          </li>
                                        ) : items.type === "public" ? (
                                          <li className="notifications">
                                            <i className="fa-solid fa-trophy"></i>
                                            <Link
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeNotification(
                                                  items._id,
                                                  "/challengemarketplace"
                                                );
                                              }}
                                            >
                                              {items.messages}
                                            </Link>
                                          </li>
                                        ) : (
                                          <li className="notifications">
                                            <i className="fa-solid fa-trophy"></i>
                                            <Link
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeNotification(
                                                  items._id,
                                                  "#"
                                                );
                                              }}
                                            >
                                              {items.messages}
                                            </Link>
                                          </li>
                                        )}
                                        {index !==
                                        allnotification.length - 1 ? (
                                          <Divider />
                                        ) : null}
                                      </React.Fragment>
                                    );
                                  })}
                              </>
                            )}
                          </ul>
                        </div>
                      </li>

                      <li className="nav-item dropdown hover">
                        <Link
                          className="nav-link dropdown-toggle nav-drop p-0 user-profile-dropdown"
                          to="/AboutRules"
                          id="navbarScrollingDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <Avatar
                            style={{
                              borderRadius: "50%",
                              width: "35px",
                              height: "35px",
                            }}
                            src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${data.avatar}`}
                            
                            // onError="this.src="
                          />
                        </Link>
                        <ul
                          style={{
                            backgroundColor: "#3C2485",
                            textAlign: "center",
                            minWidth: "90px",
                            borderRadius: "10px",
                          }}
                          className="dropdown-menu userprofile-dd-menu"
                          aria-labelledby="navbarScrollingDropdown"
                        >
                          <li>
                            <Link to="/profile">
                              <button
                                style={{
                                  padding: "5px 8px",
                                  marginBottom: "10px",
                                  // border: "2px solid #ffff",
                                }}
                                className="btn btn-outline head-btn profile"
                                type="submit"
                              >
                                {/* {data.username} */}
                                Profile
                              </button>
                            </Link>
                          </li>

                          {/* <li>
                            <Link to="/cardgallery">
                              <button
                                style={{
                                  padding: "3px 6px",
                                  marginBottom: "10px",
                                  // border: "2px solid #ffff",
                                }}
                                className="btn btn-outline head-btn profile"
                                type="submit"
                              >
                                Gallery
                              </button>
                            </Link>
                          </li> */}

                          <li>
                            <button
                              style={{
                                // border: "2px solid #ffff",
                                padding: "3px 8px",
                              }}
                              className="btn btn-outline head-btn logout"
                              onClick={logoutuser}
                            >
                              logout
                            </button>
                          </li>
                        </ul>
                      </li>
                    </div>
                  ) : (
                    <>
                      <Link to="/login" style={{ width: "max-content" }}>
                        <button
                          className="btn btn-outline head-btn"
                          type="submit"
                        >
                          Sign In
                        </button>
                      </Link>
                    </>
                  )}
                </form>
                {user?.available_fund ||
                user?.available_fund >= 0 ? (
                  <Link to="/profile/4">
                  <div className="account-bal">
                    ${(user.available_fund + user.bonus_fund).toFixed(2)}
                  </div>
                  </Link>
                ) : 
                null}
                <ul
                  className="navbar-nav mb-2 mb-lg-0 nav-menu"
                >
                  <li>
                    <a href="https://www.overwolf.com/app/Gamewagrs.com-GameWagrs" target="_blank">
                  <img loading="lazy" alt="logo" className="image" width={"180px"} src={Overwolf_App_Download} /> 
                  </a>
                  </li>
                </ul>
                {/* <div className="footer-social-media-wrapper header-icons">
                  <ul className="footer-social-media-links">
                    <li className="footer-social-media-link">
                      <a
                        href="https://twitter.com/GameWagrs"
                        target="_blank"
                      >
                        <i class="fa-brands fa-twitter  f-20"></i>
                      </a>
                    </li>
                    <li className="footer-social-media-link">
                      <a
                        href="https://www.instagram.com/duelcardsllc/"
                        target="_blank"
                      >
                        <i class="fa-brands fa-instagram  f-20"></i>
                      </a>
                    </li>
                    <li className="footer-social-media-link">
                      <a
                        href=" https://www.youtube.com/@gamewagrs"
                        target="_blank"
                      >
                        <i class="fa-brands fa-youtube f-20"></i>
                      </a>
                    </li>
                    <li className="footer-social-media-link">
                      <a
                        href="https://www.tiktok.com/@duelcardsllc"
                        target="_blank"
                      >
                        <i class="fa-brands fa-tiktok  f-20"></i>
                      </a>
                    </li>
                    <li className="footer-social-media-link">
                      <a href="https://discord.gg/NFCcznxpyZ" target="_blank">
                        <i class="fa-brands fa-discord  f-20"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
                </div>
              </div>
            {/* </div> */}
          </nav>
        </div>
      </div>
      <div className="mobile-nav fixed-top py-2">
        {[false].map((expand) => (
          <Navbar key={expand} expand={expand}>
            <ToastContainer />
            <Container fluid>
              <Link to="/">
                <img loading="lazy" alt="logo" className="mobilelogo" src={fulllogo_transparent_figma} />
              </Link>
              <div className="d-flex align-items-center">
                {localStorage.getItem("nftuser") && (
                  <div className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle nav-drop display p-0"
                      to="/AboutRules"
                      id="navbarScrollingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div
                        className="nav-item dropdown"
                        style={{ color: "#8d8ddf", position: "relative" }}
                      >
                        <Badge
                          overlap="circular"
                          badgeContent={count}
                          color="primary"
                        >
                          <Notifications
                            style={{
                              cursor: "pointer",
                              fontSize: "1.5rem",
                            }}
                          />
                        </Badge>
                      </div>
                    </Link>
                    <div
                      className="dropdown-menu notification-wrapper"
                      aria-labelledby="navbarScrollingDropdown"
                    >
                      <ul className="notification">
                        {isLoading && (
                          <div className="d-flex justify-content-center">
                            <NotificationSkeleton />
                          </div>
                        )}
                        {!isLoading && allnotification.length === 0 ? (
                          <>
                            <li
                              className="notifications no-hover"
                              style={{
                                fontSize: "12.5px",
                                color: "#fff",
                              }}
                            >
                              No new notifications
                            </li>
                          </>
                        ) : (
                          <>
                            {!isLoading &&
                              allnotification?.length > 0 &&
                              allnotification.map((items, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {items.type === "recieved" ? (
                                      <li className="notifications">
                                        <i className="fa-solid fa-user-check"></i>
                                        <Link
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeNotification(
                                              items._id,
                                              "/ChallengeReceived"
                                            );
                                          }}
                                        >
                                          {items.messages}
                                        </Link>
                                      </li>
                                    ) : items.type === "Accepted" ? (
                                      <li className="notifications">
                                        <i className="fa-solid fa-flag-checkered"></i>
                                        <Link
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeNotification(
                                              items._id,
                                              "/DuelAccepted"
                                            );
                                          }}
                                        >
                                          {items.messages}
                                        </Link>
                                      </li>
                                    ) : (
                                      <li className="notifications">
                                        <i className="fa-solid fa-trophy"></i>
                                        <Link
                                          onClick={(e) => {
                                            e.preventDefault();
                                            removeNotification(items._id, "#");
                                          }}
                                        >
                                          {items.messages}
                                        </Link>
                                      </li>
                                    )}
                                    {index !== allnotification.length - 1 ? (
                                      <Divider />
                                    ) : null}
                                  </React.Fragment>
                                );
                              })}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                  onClick={toggleCanvas}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                  show={show}
                  onHide={toggleCanvas}
                >
                  <Offcanvas.Header
                    closeButton
                    closeVariant="white"
                    className="home-offcanvas-header"
                  >
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className="home-offcanvas-body">
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      {/* <Link onClick={toggleCanvas} to="BuyDuelCard">
                        Buy DuelCards
                      </Link> */}
                      {/* <Link onClick={toggleCanvas} to="Leaderboard">
                        Leaderboard
                      </Link> */}
                      <Link onClick={toggleCanvas} to="ChallengeSomeone">
                       Challenge Someone
                      </Link>
                      <Link onClick={toggleCanvas} to="ChallengeReceived">
                        Challenge Received
                      </Link>
                      <Link onClick={toggleCanvas} to="DuelStatus">
                        Challenge Status
                      </Link>
                     <Link to="/soloshowdown" onClick={toggleCanvas} className="dropdown-item">
                          Solo ShowDown
                        </Link>
                      <Link to="/SoloShowDownStatus" onClick={toggleCanvas} className="dropdown-item">
                          Solo ShowDown Status
                        </Link>
                      {/* <Link onClick={toggleCanvas} to="Pathtolevel">
                        Path To Level 4
                      </Link>
                      <Link onClick={toggleCanvas} to="Duelsystem">
                        Duel System
                      </Link> */}
                      <Link onClick={toggleCanvas} to="challengemarketplace">
                        Challenge Marketplace
                      </Link>
                        <Link onClick={toggleCanvas} to="howtoplay">
                          How To Play
                        </Link>
                      <Link onClick={toggleCanvas} to="faqs">
                        FAQs
                      </Link>
                      { user?.available_fund ||
                        user?.available_fund >= 0 ? (
                        <Link to="/profile/4">
                        <div className="account-bal">
                          ${(user.available_fund).toFixed(2)}
                        </div>
                        </Link>
                      ) : null}
                    </Nav>

                    <form className="d-flex mobile-nav-actions">
                      {localStorage.getItem("nftuser") ? (
                        <>
                         <Nav className="justify-content-end flex-grow-1 pe-3 no-nav">
                          <Link to="/profile" onClick={toggleCanvas}>
                            {/* <button
                              className="btn btn-outline head-btn profile-btn"
                              // type="submit"
                            > */}
                              Profile
                            {/* </button> */}
                          </Link>
                         

                          <button
                            className="btn btn-outline head-btn logout-btn"
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              logoutuser();
                              setShow((show) => !show);
                            }}
                          >
                            Logout
                          </button>
                          </Nav>
                        </>
                      ) : (
                        <Link to="/login">
                          <button
                            className="btn btn-outline head-btn signIn-btn"
                            onClick={toggleCanvas}
                            type="submit"
                          >
                            Sign In
                          </button>
                        </Link>
                      )}
                    </form>
                    <div className="footer-social-media-wrapper header-icons page-bottom">
                      <SocialMediaLinks header={true} />
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </div>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
};
export default Header;
