import React,{ useEffect, useState } from 'react'
import axios from "axios";
import { toast } from 'react-toastify';
import './OverWolf.css'
import { Loader } from './Loader';

const OverWolf = () => {
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  const getUserDetail = async () => {
    setIsLoading(true);
    await getOverwolfData();
    setIsLoading(false);
    window.close();
  };
  /**
   * @description Get the overwolf data from the overwolf API and save it to the user's local storage
   */
  async function getOverwolfData(){
    try {
      const tokenResponse = await axios.post(`/api/auth/getOverwolfToken?code=${code}&code_verifier=${localStorage.getItem("overwolfVerifier")}`);
      if(!tokenResponse?.data?.success) return;
      window.opener.postMessage({ type: "overwolfToken", overwolfToken: tokenResponse.data }, process.env.REACT_APP_BASE_URL);
      const userDataResponse = await axios.get(`/api/auth/getOverwolfUserData?access_token=${tokenResponse.data.access_token}`);
      if(!userDataResponse?.data?.success) return;
      window.opener.postMessage({ type: "overwolfUserData", overwolfData: {...userDataResponse.data.data, code: tokenResponse.data.access_token }}, process.env.REACT_APP_BASE_URL);
    } catch (error) {
      console.log("OverwolfData Error:",error);
    }
  }

  useEffect(() => {
    getUserDetail()
  }, [code])

  return (
    <div className='overwolf-main'>
      {isLoading && (
        <Loader />
      )}
      <h2>Please wait... The process may take a moment.</h2>
    </div>
  )
}

export default OverWolf