import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import "./DuelStatus.css";
import { Loader } from "../component/Loader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import DuelStatusSkeleton from "../skeletons/DuelStatusSkeleton";
import moment from "moment-timezone";
import { Button, Modal, Spinner } from "react-bootstrap";
import { errorToast } from "../constants/ToastNotifications";
import { loaduser } from "../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
export const DuelStatus = () => {
  const [challengedata, setchallengedata] = useState([]);
  const data = JSON.parse(localStorage.getItem("nftuser"));
  const [loading, setLoading] = useState(false);
  const [showmodal, setShowModal] = useState(false);
  const [showchallno, setShowChallNo] = useState("");
  const [cancelreqid, setCancelReqId] = useState("");
  const [showopponentname, setShowOpponentName] = useState("");
  const [requiredChecked, setRequiredChecked] = useState(false);
  const [showcheckboxmsg, setShowCheckboxMsg] = useState("");
  const [showconfirmationmodal, setShowConfirmationModal] = useState(false);
  const [duelstatusloading,setDuelStatusLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // MUI styles override

  const useStyles = makeStyles({
    ts: {
      color: "#ffffff",
      fontSize: `${window.innerWidth > 400 ? "15px" : "13px"}`,
      fontFamily: "Roboto,sans-serif",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      "&:focus": {
        color: "#ffffff",
      },
    },
    selectDropdown: { color: "#fff", backgroundColor: "#0b0b27" },
    menuItem: {
      "&:hover": {
        backgroundColor: "#06001b",
      },
      select: {
        color: "red",
        background: "white",
      },
    },
  });

  const CustomTooltip = withStyles({
    tooltip: {
      color: "white",
      fontSize: "13px",
    },
  })(Tooltip);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#0b0b27",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#06001b",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableHeader = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#06001b",
    },
  }));
  const classes = useStyles();

  const getrecieved = async () => {
    setLoading(true);
    console.log(data._id)
    const res = await axios.post("/api/auth/challengestatus", { id: data._id });
    setLoading(false);
    setchallengedata(res.data);
  };
  const closeModalHandler = () => {
    setShowModal(false);
    setRequiredChecked(false);
    setShowCheckboxMsg("");
    // setIsTicked(true);
  };
  const closeConfimationModalHandler = () => {
    setShowConfirmationModal(false)
  }
  const cancelRequest = async () => {
    if(requiredChecked){
      setDuelStatusLoading(true);
      const cancelreq = await axios.post("/api/auth/cancelrequest",{ id: cancelreqid });

      if(cancelreq?.status == 200){
        getrecieved();
        dispatch(loaduser());
        setDuelStatusLoading(false);
        setShowModal(false);
        setShowConfirmationModal(true);
        setRequiredChecked(false);
      } else {
        setDuelStatusLoading(false);
        errorToast("Something went wrong please try again");
        setShowModal(false);
        setShowConfirmationModal(false);
        setRequiredChecked(false);
      }
    } else {
      setShowCheckboxMsg("Please verify above checkbox for cancel this request")
    }
  }
  const getexpire = async () => {
    const res = await axios.put("/api/auth/setexpire", { date: Date.now() });
  };

  useEffect(() => {
    getrecieved();
    getexpire();
  }, []);

  const handleRequiredChange = (event) => {
    setRequiredChecked(event.target.checked);
    if(event.target.checked == true){
      setShowCheckboxMsg("");
    }else {
      setShowCheckboxMsg("Please verify above checkbox for cancel this request");
    }
  };

  const goToChallengeReceived = (itemdata,itemchallno,itemOppname,itemId) => {
    if(itemdata == data.username) {
      setShowChallNo(itemchallno);
      setCancelReqId(itemId);
      setShowOpponentName(itemOppname)
      setShowModal(true);
    } else {
      navigate('/ChallengeReceived')
    }
  }
  return (
    <>
      <div className="duel-status">
        <div className="status-table">
          <div className="duelstatus-user-title" style={{ marginBottom: "20px" }}>
            <h1>{data.username}</h1>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <StyledTableHeader>
                <TableCell className={classes.ts} align="center">
                    Challenge No
                  </TableCell>
                  <TableCell className={classes.ts} align="center">
                    Challenger
                  </TableCell>
                  <TableCell className={classes.ts} align="center">
                    Reciever
                  </TableCell>
                  <TableCell className={classes.ts} align="center">
                    Status
                  </TableCell>
                  <TableCell className={classes.ts} align="center">
                    Go to challenge
                  </TableCell>
                  <TableCell className={classes.ts} align="center">
                    Result
                  </TableCell>
                  <TableCell className={classes.ts} align="center">
                    Challenge Date
                  </TableCell>
                </StyledTableHeader>
              </TableHead>
              {loading ? (
                <TableBody>
                  <DuelStatusSkeleton />
                </TableBody>
              ) : challengedata?.length > 0 ? (
                <TableBody>
                  {challengedata.map((items, index) => (
                    <StyledTableRow
                      key={items.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                     
                       <TableCell className={classes.ts} align="center">
                        {items?.challenge_number}
                      </TableCell>
                      <TableCell
                        className={classes.ts}
                        align="center"
                        width={"12%"}
                      >
                        <div className="avatar-name">
                          <Avatar
                            src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${items?.player_1_id?.avatar}`}
                            className="table-avatar"
                            sx={{ width: 25, height: 25 }}
                          />
                          <div className="avatar-name-1">
                            {items?.player_1_id?.username ||
                              items?.player_1[0]?.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.ts}
                        align="center"
                        width={"12%"}
                      >
                        <div className="avatar-name">
                          <Avatar
                            src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${items?.player_2_id?.avatar}`}
                            className="table-avatar"
                            sx={{ width: 25, height: 25 }}
                          />
                          <div className="avatar-name-1">
                            {items?.player_2_id?.username ||
                              items?.player_2[0]?.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.ts} align="center">
                        {items.Accept === "true" && items.result === "pending"
                          ? "Accepted"
                          : items.Accept === "decline"
                          ? "Declined"
                          : items.Accept === "pending"
                          ? "Pending"
                          : items.Accept === "abanded"
                          ? "Abandoned"
                          : items.Accept === "cancelled"
                          ? "Cancelled"
                          : "Declared"}
                      </TableCell>
                      <TableCell className={classes.ts} align="center" >
                        {items.Accept === "true" &&
                        items.result === "pending" ? (
                          // <Link to="/DuelAccepted" state={{ city: "Paris" }}>
                          <Link to = {`/${"DuelAccepted"}/${items?._id}`}>
                            <button className="table-hero-btn link-btn">
                              <i
                                className="fa-solid fa-link"
                                style={{ marginRight: "5px" }}
                              ></i>
                              {`${window.innerWidth < 700 ? "" : "Challenge"}`}
                            </button>
                          </Link>
                        ) : items.Accept === "pending" &&
                          items.result === "pending" ? (
                          // <Link to="/ChallengeReceived">
                            <button onClick={() => goToChallengeReceived(items?.player_1_id?.username,items?.challenge_number,items?.player_2_id?.username,items?._id)} className="table-hero-btn link-btn">
                              <i
                                className="fa-solid fa-link"
                                style={{ marginRight: "5px" }}
                              ></i>{" "}
                              {`${window.innerWidth < 700 ? "" : "Challenge"}`}
                            </button>
                       
                        ) : items.result === "pending" ||
                          items.result === "Manual Review" ||
                          items.result === "cancelled" ||
                          items.result === "abanded" ? (
                          "-"
                        ) : (
                          <Link
                            to={`/${
                              items.winner === data?._id ? "winner" : "loser"
                            }/${items?._id}/${
                              items.winner !== items.player_1_id?._id
                                ? "player_1"
                                : "player_2"
                            }/${items?.challenge_number}`}
                          >
                            <button className="table-hero-btn link-btn">
                              <i
                                className="fa-solid fa-link"
                                style={{ marginRight: "5px" }}
                              ></i>{" "}
                              {`${window.innerWidth < 700 ? "" : "Challenge"}`}
                            </button>
                          </Link>
                        )}
                      </TableCell>
                      <TableCell className={classes.ts} align="center">
                        {items.result === "pending" ? (
                          <div>pending</div>
                        ) : items.result === "Manual Review" ? (
                          <div>Manual Review</div>
                        ) : items.result === "abanded" ? (
                          <div>Abandoned</div>
                        ) : items.result === "cancelled" ? (
                          <div>Cancelled</div>
                        ) : items.winner === data._id ? (
                          <div style={{ color: "#0ef06c" }}>Winner</div>
                        ) : (
                          <div style={{ color: "#d82626" }}>Loser</div>
                        )}
                      </TableCell>
                      <TableCell className={classes.ts} align="center">
                      {items?.challenge_created ?<>{moment(items?.challenge_created).tz(moment.tz.guess()).format("MM/DD/YYYY h:mm A")}</> : <>---</> }
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          {challengedata?.length === 0 && !loading && (
            <div className="no-records-found">No records found.</div>
          )}
        </div>
        {showmodal && <div className="modal challenge-someone-modal">
                        <div className="modal-content duelstatus-modal-content">
                        <Modal.Header className="challengesomeone-header">
                        <button onClick={closeModalHandler} className="modal-button challengesomeone-cancel-btn">&times;</button>
                        <Modal.Title className="modal-header-font">Challenge Status</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p className="duelstatus-modal-text"> Challenge No : <span style={{fontFamily:'Roboto,sans-serif'}}>{showchallno}</span></p>
                          <p>Your opponent <span className="duelstatus-modal-text">{showopponentname}</span> has not yet accepted your challenge. Please wait until they accept your challenge.</p>
                          <p><span className="duelstatus-modal-checkbox-text">Cancel Challenge ?</span><input className="required-tick" type="checkbox" required onChange={handleRequiredChange} checked={requiredChecked} /></p>
                          <p className="duel-status-checkbox-msg">{showcheckboxmsg}</p>
                          </Modal.Body>
                          <Modal.Footer>
                            <div className="duelstatus-modal-btns-align">
                          <button onClick={() =>closeModalHandler()}>{duelstatusloading ? <Spinner animation="border" size="sm"/> :"Close"}</button>
                          <button onClick={() =>cancelRequest()}> {duelstatusloading ? <Spinner animation="border" size="sm"/> :"Cancel Challenge"}</button>
                          </div>
                          </Modal.Footer>
                        </div>
                      </div>}
        {showconfirmationmodal && <div className="modal challenge-someone-modal">
                        <div className="modal-content duelstatus-confirmation-modal-content">
                        <Modal.Header className="challengesomeone-header">
                        <button onClick={closeConfimationModalHandler} className="modal-button challengesomeone-cancel-btn">&times;</button>
                        <Modal.Title className="modal-header-font">Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* <p className="duelstatus-modal-text"> Challenge No : <span style={{fontFamily:'Roboto,sans-serif'}}>{showchallno}</span></p> */}
                          <p>Your challenge <span className="duelstatus-modal-text">{showchallno}</span> has been canceled and your service fee has been refunded.</p>
                          </Modal.Body>
                          <Modal.Footer>
                          <button onClick={() =>closeConfimationModalHandler()}>Close</button>
                          </Modal.Footer>
                        </div>
                      </div>}
      </div>
    </>
  );
};

export default DuelStatus;
