import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./login.module.css";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Spinner, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login, overwolfLogin } from "../actions/userAction";
// import { useAlert } from "react-alert";
import { Loader } from "./Loader";
import { toast, ToastContainer } from "react-toastify";
import { errorToast } from "../constants/ToastNotifications";
import {
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  USER_REFERRAL_REQUEST,
  USER_REFERRAL_SUCCESS,
  USER_REFERRAL_FAIL
} from "../constants/userConstants";
import CryptoJS from "crypto-js";
import overwolfImage from "../images/Overwolf_logo.png";
import logo from "../images/new-logo-2.png"

export const Login = () => {
  const { isAuthenticated, user, error, loading } = useSelector(
    (state) => state.user
  );
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [inputData, setInputData] = useState({ email: "", password: "" });
  //   const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = ({ currentTarget: input }) => {
    setInputData({ ...inputData, [input.name]: input.value });
    //console.log(inputData);
  };
  const toastnotification = async (notes, id) => {
    toast.info(notes, {
      position: "top-right",
      autoClose: 5000,
      toastId: id,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(inputData));
  };

  useEffect(() => {
    const overwolfDataCheck = (event) => {
      if (event.origin !== process.env.REACT_APP_BASE_URL ||  event.data?.type !== "overwolfToken") return;
      if (!event.data?.overwolfToken?.access_token) return;

      dispatch(overwolfLogin(event.data.overwolfToken.access_token));
    };

    window.addEventListener("message", overwolfDataCheck);

    return () => {
      window.removeEventListener("message", overwolfDataCheck);
    };
  },[]);
  
  const handleOverwolfLogin = async (e) => {
    e.preventDefault();
    const overwolfAuthBaseUrl = 'https://id.overwolf.com/oidc/auth';
    
    function generateCodeVerifier() {
      const randomArray = new Uint8Array(32);
      window.crypto.getRandomValues(randomArray);
      return btoa(String.fromCharCode(...randomArray))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    }
    function generateCodeChallenge(codeVerifier) {
      const hash = CryptoJS.SHA256(codeVerifier);
      const base64String = hash.toString(CryptoJS.enc.Base64);
      return base64String
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    }

    const codeVerifier = generateCodeVerifier();
    localStorage.setItem("overwolfVerifier", codeVerifier);
    const codeChallenge = generateCodeChallenge(codeVerifier);

    const authParams =(
      "?client_id=" + process.env.REACT_APP_OVERWOLF_CLIENT_ID +
      "&redirect_uri=" + process.env.REACT_APP_OVERWOLF_REDIRECT_URI +
      "&scope=openid+profile+email" +
      "&code_challenge=" + codeChallenge +
      "&code_challenge_method=S256" +
      "&response_type=code"
    );

    const overwolfFullAuthUrl = overwolfAuthBaseUrl + authParams;

    const popupWindow = window.open(
      overwolfFullAuthUrl,
      "_blank"
    );
  };

  const verifyUser = async (userId, otp) => {
    dispatch({ type: VERIFY_USER_REQUEST });
    setLoadingMessage("Verifying user...");
    const { data } = await axios.get(
      `/api/auth/verifyUser?userId=${userId}&otp=${otp}`
    );
    dispatch({ type: data.success ? VERIFY_USER_SUCCESS : VERIFY_USER_FAIL});
    if (data.success) {
      toastnotification(
        <div style={{ color: "white" }}>
          {"Your account is verified successfully."}
        </div>
      );
    }
    if(data?.user?.isReferred){
      dispatch({ type: USER_REFERRAL_REQUEST});
      setLoadingMessage("Applying referral code funds...");
      const referralRes = await axios.post(`/api/auth/referupdatebalme`, {_id: data?.user?._id});
      const { success, message } = referralRes?.data;
      if(success){
        toastnotification(
          <div style={{ color: "white" }}>
            {"Referral code funds applied!"}
          </div>
        );
      } else {
        errorToast("Error:", !!message ? message : "Referral code funds could not be applied.");
        await new Promise(r => setTimeout(r, 5000));
        dispatch({ type: USER_REFERRAL_FAIL });
      }
      dispatch({ type: USER_REFERRAL_SUCCESS });
    }
    navigate("/VerifyUser", { state: data.user });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get("userId");
    const otp = queryParams.get("otp");
    if (userId && otp) verifyUser(userId, otp);
  }, [window.location.search]);

  useEffect(() => {
    if (error) {
      errorToast(error);
    }
  }, [error, isAuthenticated, navigate]);

  if (localStorage.getItem("nftuser")) {
    navigate("/ChallengeSomeone");
  }
  return (
    <>


      {loading ? (
        <div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            flexDirection: 'column',
            position: 'absolute',
            top: `calc(6vh + 20px)`
            }}>
            <p style={{ color: 'white' }}>{`${loadingMessage}`}</p>
          </div>
          <Loader />
        </div>
      ) : (
    <>
      <div className="body-main">
        <div className="login-sec">
          <Container>
            <>
                <div className={styles.login_container}>
                  <div className={styles.login_form_container}>
                    <div className={styles.left}>
                      <form
                        className={styles.form_container}
                        onSubmit={handleSubmit}
                      >
                        <h1>Login</h1>
                        <button
                          type="button"
                          onClick={handleOverwolfLogin}
                          className={styles.overwolf_login_button}
                        >
                          <img src={overwolfImage} alt="Overwolf" className={styles.overwolf_login_image} />
                          Login using Overwolf
                        </button>
                        <div>
                          <span className={styles.or_divider}>OR</span>
                        </div>
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                          value={inputData.email}
                          required
                          className={styles.input}
                          style={{ marginBottom: "14px" }}
                          autoComplete="email"
                        />
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={handleChange}
                          value={inputData.password}
                          required
                          className={styles.input}
                        />
                          <button type="submit" className={styles.login_btn}>
                            {loading ? <Spinner animation="border" size="sm" /> : "Login"}
                          </button>
                        </form>
                        <div className={styles.left_inner}>
                          <Link to="/forgotpassword">
                            <button type="link" className={styles.register_link}>
                              Forgot Password?
                            </button>
                          </Link>
                        </div>
                        <div className={styles.left_inner_register}>
                          <div className={styles.new_user}>
                            Don&apos;t have an account?
                            <Link to="/register">
                              <button type="link" className={styles.register_link}>
                                {loading ? <Spinner animation="border" size="sm" /> : "Sign Up"}
                              </button>

                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <div className={styles.right_inner}>
                          <img src={logo} alt="Logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Container>
            </div>
          </div>
        </>)}
    </>
  );
};
