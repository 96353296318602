import React, { useState, useEffect, useRef } from "react";
import CryptoJS from "crypto-js";
import axios from "axios";
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userAction";
import { Loader } from "../component/Loader";
import { loaduser } from "../actions/userAction";
import Resizer from "react-image-file-resizer";
import img1 from "../images/Profile.png";
import logo from "../images/new-logo-2.png"
import { errorToast, infoToast } from "../constants/ToastNotifications";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createStyles} from '@mui/styles';
import { createTheme,ThemeProvider} from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import Avatar from "@material-ui/core/Avatar";
import checkmark from "../images/Check_ring_winner.svg";
import Select from 'react-select';
import countrylist from '../country-states.json';
import "../Pages/Profile.css";
export const Register = () => {
 
  const { loading, isAuthenticated, user, error } = useSelector(
    (state) => state.user
  );
  
  const [registerLoading,setRegisterLoading] = useState(false)
  const dispatch = useDispatch();
  const selectInputRef = useRef();
  // const alert = useAlert();
  const navigate = useNavigate();
  const [validatingUsername, setValidatingUsername] = useState(false);
  const [usernameIsValid, setUsernameIsValid] = useState(false);
  const [inputValue, setinputValue] = useState({
    username: "",
    email: "",
    dateOfBirth: "",
    password: "",
    avatar: "",
    referralCode: "",
    overwolfData: null
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const useStyles = makeStyles((theme) =>
  createStyles({
   
    datePickerContainer: {
      marginTop: "0.6rem"
    },
    datePicker: {
      height:"45px",
      width: "100%",
	    borderRadius: "10px",
	    backgroundColor: "#dee2e6",
	    margin: "5px 0",
      fontSize: "14px",
	    color: "#1b193c",
    '& .MuiOutlinedInput-root': {
    border: 'none',
    
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input': {
    color: "gray",
    fontSize:"14px",
    textTransform:'lowercase',
  },
 
    },
    tabletStyler:{
      width:"100%",
      height:"45px",
      borderRadius: "10px",
	    backgroundColor: "#dee2e6",
	    margin: "5px 0",
      fontSize: "14px",
	    color: "#1b193c",
      '& .MuiOutlinedInput-root': {
        border: 'none',
        
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-input': {
        color: "gray",
        fontSize:"14px",
        textTransform:'lowercase',
      },
    },
    mobileStyler:{
      width:"250px",
      maxWidth:"100%",
      height:"45px",
	    borderRadius: "10px",
	    backgroundColor: "#dee2e6",
	    margin: "5px 0",
      fontSize: "14px",
	    color: "#1b193c",
      '& .MuiOutlinedInput-root': {
        border: 'none',
        
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-input': {
        color: "gray",
        fontSize:"14px",
        textTransform:'lowercase',
      },
    }
  })
);


  const classes = useStyles();
  const tabletsize=useMediaQuery('(max-width:558px)');
  const mobilesize=useMediaQuery('(max-width:310px)')
  const handleChange = async (event) => {
    const { name, value } = event.target;
    setinputValue({ ...inputValue, [name]: value });
  
    console.log(inputValue);
  };

  //Username validation
  useEffect(() => {
    if(!inputValue.username.trim()) return;
    setValidatingUsername(true);

    const controller = new AbortController();
    const signal = controller.signal;

    const timer = setTimeout(() => {
      handlecheckusername(signal)
        .then((isValid) => {
          if(isValid === null) return;
          setValidatingUsername(false);
          setUsernameIsValid(isValid);
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
      controller.abort();
    };
  }, [inputValue.username]);

  //Check if username is available
  const handlecheckusername = async (abortSignal) => {
    let data;
    try{
      const response = await axios.post(`/api/auth/checkUsername`, {username:inputValue.username}, {signal:abortSignal});
      data = response.data;
    } catch (error) {
      if (error.name === 'AbortError') return null;
    }
    if(!data) return null;
    if(data.success) return true;
    if(!data.success && data?.message){
      errorToast(data?.message);
      setRegisterLoading(false)
    }
    return false;
  };

  //Username validation info that appears below input field
  const usernameValidationInfo = () => {
    if(inputValue.username.trim().length > 0){
      if(validatingUsername){
        return <p className={styles.register_username_info}><CircularProgress size={8} style={{marginRight: '5px'}}/>Validating Username...</p>;
      } else if(usernameIsValid){
        return <p className={`${styles.register_username_info} ${styles.success}`}>Username is available</p>;
      } else {
        return <p className={`${styles.register_username_info} ${styles.fail}`}>Username is taken</p>;
      }
    }
  }

  const validateReferralCode = async () => {
    if(!inputValue.referralCode.trim()) return true;
    const res = await axios.post(`/api/auth/checkReferralCode`, {referralCode: inputValue.referralCode});
    if(!res.data?.success) return false;
    return res.data?.isValid;
  };

  //Check if user is above 18 years old
  const validateAge = (dateString) => {
    const today = new Date();
    const selectedDate = new Date(dateString);
    let age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selectedDate.getDate())) {
      age--;
    }
  
    return age >= 18;
  };

  // const handleimage = (e) => {
  //   console.log(e.target.files);
  //   setSelectedImage(e.target.files);

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     if (reader.readyState === 2) {
  //       // setavatarPreview(reader.result);
  //       setAvatarpreview(reader.result);
  //     }
  //   };

  //   reader.readAsDataURL(e.target.files[0]);
  // };

  //Handles the country dropdown
  const handleCountry = (value) => {
    setSelectedState(null);
    setSelectedCountry(value.value);
    setStateList(() => {
      return countrylist.countries.find(item => item.country === value.value).states
    })
  }

  //Handles the state dropdown
  const handleState = (value) => {
    if(value?.value === "Arizona"  ||
      value?.value === "Arkansas" ||
      value?.value === "Connecticut" ||
      value?.value === "Delaware" ||
      value?.value === "Florida" ||
      value?.value === "Indiana" ||
      value?.value === "Louisiana" ||
      value?.value === "Maryland" ||
      value?.value === "Minnesota" ||
      value?.value === "Montana" ||
      value?.value === "South Carolina" ||
      value?.value === "South Dakota" ||
      value?.value === "Tennessee" ||
      value?.value === "Wyoming"
    ){
      setSelectedState(null);
      errorToast("We regret to inform you that our services are not available in your state.")
    } else {
      setSelectedState(value);
    }
  }

  //Handles the input fields
  const handleUsername = (e) => {
    setinputValue({ ...inputValue, username: e.target.value });
  }
  const handleEmail = (e) => {
    setinputValue({ ...inputValue, email: e.target.value });
  }
  const handlePassword = (e) => {
    setinputValue({ ...inputValue, password: e.target.value });
  }
  const handleReferralCode = (e) => {
    setinputValue({ ...inputValue, referralCode: e.target.value });
  }

  //Verifies that the user is above 18 years old
  function validateDateOfBirth(){
    const Date=inputValue.dateOfBirth.$D;
    const month=inputValue.dateOfBirth.$M;
    const year=inputValue.dateOfBirth.$y;
    const exactMonth=month+1;
    const formattedDate=`${year}-${exactMonth}-${Date}`;

    if(!Date || !exactMonth || !year){
      errorToast("Please select your birthday");
      setActiveStep(0)
      return false;
    }
    const isAbove18 = validateAge(inputValue.dateOfBirth);
    if(formattedDate && !isAbove18){
      errorToast("You must be at least 18 years old to participate on Gamewagrs.com");
      console.log("less than 18 years.");
      setActiveStep(0)
      return false;
    }
    return true;
  }
  //Validates that the user has selected a country
  function validateCountry(){
    if(!selectedCountry || selectedCountry == null){
      errorToast("Please select your country");
      setActiveStep(0)
      return false;
    }
    return true;
  }
  //Validates that the user has selected a state
  function validateState(){
    if(!selectedState || selectedState == null){
      errorToast("Please select your state");
      setActiveStep(0)
      return false;
    }
    return true;
  }
  //Validates that the user has connected their Overwolf account
  function validateOverwolfData(){
    const isOverwolfConnected = !!inputValue.overwolfData;
    if(!isOverwolfConnected){
      errorToast("Please connect your Overwolf account to continue");
      setActiveStep(1)
      return false;
    }
    return true;
  }
  //Validates that the username is valid
  function validateUsername(){
    const isUsernameValid = (
      !!inputValue.username &&
      inputValue.username.length > 0 &&
      !validatingUsername &&
      usernameIsValid
    );
    if(!isUsernameValid){
      errorToast("Please enter a valid username");
      setActiveStep(2);
      return false;
    }
    return true;
  }
  //Validates that the email is correctly formatted
  function verifyEmailSyntax(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email)
  }
  //Validates that the email is valid
  function validateEmail(){
    const isEmailValid = (
      !!inputValue.email &&
      inputValue.email.length > 0 &&
      verifyEmailSyntax(inputValue.email)
    );
    if(!isEmailValid){
      errorToast("Please enter a valid email address");
      setActiveStep(2);
      return false;
    }
    return true;
  }
  //Validates that the password is valid
  function validatePassword(){
    let passwordValid = false;
    if(!inputValue.password){
      errorToast("Please enter a password");
    } else if(inputValue.password.trim().length < 6){
      errorToast("Password must be at least 6 characters long");
    } else {
      passwordValid = true;
    }

    if(!passwordValid){
      setActiveStep(2);
      return false;
    }
    return true;
  }

  //Validates all registration data and makes sure it's ready for submission
  function validateRegistrationData() {
    return (
      validateDateOfBirth() &&
      validateCountry() &&
      validateState() &&
      validateOverwolfData() &&
      validateUsername() &&
      validateEmail() &&
      validatePassword() &&
      validateReferralCode()
    );
  }

  //Submit data and attempt registration
  const handleSubmit = async (e) => {
    setRegisterLoading(true);
    e.preventDefault();
    if(!validateRegistrationData()) { setRegisterLoading(false); return;}

    const success = dispatch(
      register(
      {
        username: inputValue.username,
        email: inputValue.email,
        password: inputValue.password,
        avatar: null,
        dateOfBirth: inputValue.dateOfBirth,
        country: selectedCountry,
        state: selectedState.value,
        overwolfCode: inputValue.overwolfData?.code,
        referralCode: inputValue.referralCode
      },
      navigate,
      infoToast
      )
    );
    setRegisterLoading(false);
  };

  useEffect(() => {
    if (error) {
      errorToast(error.message);
    }
  }, [error, isAuthenticated, navigate]);
  
  if (localStorage.getItem("nftuser")) {
    navigate("/ChallengeSomeone");
  }

  useEffect(() => {
    const overwolfDataCheck = (event) => {
      if (event.origin !== process.env.REACT_APP_BASE_URL || event.data?.type !== "overwolfUserData") return;
      setinputValue(prev => ({
        ...prev,
        email: event.data.overwolfData.email,
        username: event.data.overwolfData.nickname,
        overwolfData: event.data.overwolfData
      }));
    };

    window.addEventListener("message", overwolfDataCheck);

    return () => {
      window.removeEventListener("message", overwolfDataCheck);
    };
  },[]);

  //Redirect user to Overwolf login page and sets the intent for what to do with the overwolf data
  const handleOverwolf = () => {
    const overwolfAuthBaseUrl = 'https://id.overwolf.com/oidc/auth';

    function generateCodeVerifier() {
      const randomArray = new Uint8Array(32);
      window.crypto.getRandomValues(randomArray);
      return btoa(String.fromCharCode(...randomArray))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    }
    function generateCodeChallenge(codeVerifier) {
      const hash = CryptoJS.SHA256(codeVerifier);
      const base64String = hash.toString(CryptoJS.enc.Base64);
      return base64String
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    }

    const codeVerifier = generateCodeVerifier();
    localStorage.setItem("overwolfVerifier", codeVerifier);
    const codeChallenge = generateCodeChallenge(codeVerifier);

    const authParams =(
      "?client_id=" + process.env.REACT_APP_OVERWOLF_CLIENT_ID +
      "&redirect_uri=" + process.env.REACT_APP_OVERWOLF_REDIRECT_URI +
      "&scope=openid+profile+email" +
      "&code_challenge=" + codeChallenge +
      "&code_challenge_method=S256" +
      "&response_type=code"
    );

    const overwolfFullAuthUrl = overwolfAuthBaseUrl + authParams;

    const popupWindow = window.open(
      overwolfFullAuthUrl,
      "_blank"
    );
    if (localStorage.getItem("nftuser")) {
      dispatch(loaduser());
    }
  };

  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : ( */}
        <>
          <div className="body-main pad-bot">
          <Container>
            <div className={styles.signup_container}>
              <div className={styles.signup_form_container}>
                <div className={styles.left}>
                  <div className={styles.left_inner}>
                    <img src={logo} alt="Logo"/>
                  </div>
                </div>
                <div className={styles.right}>
                  <form className={styles.form_container}>
                    <h1 className={styles.signup_header}>Account Creation</h1>
                    <div className={styles.signup_step_container}>
                      <div className={styles.signup_step_header} onClick={() => setActiveStep(0)}>
                        <div className={styles.signup_step_label}>
                          Step 1
                        </div>
                        </div>
                      <div className={styles.signup_step_content_container}>
                        <div className={`${styles.signup_step_content} ${activeStep === 0 ? styles.selected_step : ""}`}>
                          <div className={styles.form_field}>
                            <label className={styles.register_form}>Date Of Birth</label>
                            <div className={styles.input_wrapper}>
                              <div className={classes.datePickerContainer}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker className={mobilesize?classes.mobileStyler:tabletsize?classes.tabletStyler:classes.datePicker} value={inputValue.dateOfBirth}
                                  onChange={(date) =>setinputValue({ ...inputValue, dateOfBirth: date })}/>
                                </LocalizationProvider>
                              </div>
                              <span className={styles.required_indicator}>*</span>
                            </div>
                          </div>
                          <div className={styles.form_field}>
                            <label className={styles.register_form}>Country</label>
                            <div className={styles.input_wrapper}>
                              <Select
                                className="country-select select-field add-full-width mt-3"
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: 'white',
                                  }),
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures dropdown is above other elements
                                }}
                                placeholder="Select country"
                                defaultValue={selectedCountry}
                                onChange={(value) => handleCountry(value)}
                                options={countrylist.countries.map(item => ({
                                  value: item.country,
                                  label: item.country
                                }))}
                                menuPortalTarget={document.body} // Moves dropdown to body to escape clipping
                                menuPosition="absolute"
                              />
                              <span className={styles.required_indicator}>*</span>
                            </div>
                          </div>
                          <div className={styles.form_field}>
                            <label className={styles.register_form}>State</label>
                            <div className={styles.input_wrapper}>
                              <Select
                                className="state-select select-field mt-3"
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures dropdown is above other elements
                                }}
                                placeholder="Select state"
                                value={selectedState}
                                onChange={(value) => handleState(value)}
                                isDisabled={!selectedCountry}
                                options={stateList.map(item => ({ value: item, label: item }))}
                                menuPortalTarget={document.body} // Moves dropdown to body to escape clipping
                                menuPosition="absolute"
                              />
                              <span className={styles.required_indicator}>*</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.signup_step_container}>
                      <div className={styles.signup_step_header} onClick={() => setActiveStep(1)}>
                        <div className={styles.signup_step_label}>
                          Step 2
                        </div>
                      </div>
                      <div className={styles.signup_step_content_container}>
                        <div className={`${styles.signup_step_content} ${activeStep === 1 ? styles.selected_step : ""}`}>
                          <div>
                            <p className={styles.ow_header_info}>In order to participate in challenges, you need to connect your Overwolf Account with your Gamewagrs.com account</p>
                            <p className={styles.ow_text_info}>Click on 'Connect Overwolf' Button </p>
                            <p className={styles.ow_text_info}>A new window opens - If you already have an Overwolf account, enter your details in the popup</p>
                            <p className={styles.ow_text_info}>Don't have an Overwolf account? Click on 'Create Account'.</p>
                          </div>
                          <div>
                            <div>
                              <button type="button" className="ow-login-btn" onClick={handleOverwolf}>Connect Overwolf</button>
                              {inputValue.overwolfData && <img src={checkmark} alt="checkmark"/>}
                            </div>
                            {inputValue.overwolfData &&
                              <div className={styles.register_ow_field}>
                                <label className={styles.register_ow_header}>Connected Overwolf Account:</label>
                                <div style={{marginLeft: '20px'}}>
                                  {inputValue.username &&
                                    <div>
                                      <label htmlFor="username" style={{width: '90px'}} className={styles.register_ow_label}>Username</label>
                                      <label className={styles.register_ow_label}>-</label>
                                      <label htmlFor="owUsername" className={styles.register_ow_value}>{inputValue.overwolfData?.nickname??null}</label>
                                    </div>
                                  }
                                  {inputValue.email &&
                                    <div>
                                      <label htmlFor="email" style={{width: '90px'}} className={styles.register_ow_label}>Email</label>
                                      <label className={styles.register_ow_label}>-</label>
                                      <label htmlFor="owEmail" className={styles.register_ow_value}>{inputValue.overwolfData?.email??null}</label>
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.signup_step_container}>
                      <div className={styles.signup_step_header} onClick={() => setActiveStep(2)}>
                        <div className={styles.signup_step_label}>
                          Step 3
                        </div>
                      </div>
                      <div className={styles.signup_step_content_container}>
                        <div className={`${styles.signup_step_content} ${activeStep === 2 ? styles.selected_step : ""}`}>
                          <div>
                            {/* ----USERNAME---- */}
                            <div className={styles.form_field}>
                              <label htmlFor="username" className={styles.register_form}>Username</label>
                              <div className={styles.input_wrapper}>
                                <input
                                  type="text"
                                  placeholder="Username"
                                  id="username"
                                  name="username"
                                  onChange={handleUsername}
                                  value={inputValue.username}
                                  maxLength={48}
                                  
                                  className={styles.input}
                                />
                              </div>
                            </div>
                            {/* ----USERNAME VALIDATION---- */}
                            {usernameValidationInfo()}
                            {/* ----EMAIL---- */}
                            <div className={styles.form_field}>
                              <label htmlFor="email" className={styles.register_form}>Email</label>
                              <div className={styles.input_wrapper}>
                                <input
                                  type="text"
                                  placeholder="Email"
                                  id="email"
                                  name="email"
                                  onChange={handleEmail}
                                  value={inputValue.email}
                                  
                                  className={styles.input}
                                />
                              </div>
                            </div>
                            {/* ----PASSWORD---- */}
                            <div className={styles.form_field}>
                              <label htmlFor="password" className={styles.register_form}>Password</label>
                              <div className={styles.input_wrapper}>
                                <input
                                  type="text"
                                  placeholder="Password"
                                  id="password"
                                  name="password"
                                  onChange={handlePassword}
                                  value={inputValue.password}
                                  
                                  className={styles.input}
                                />
                              </div>
                            </div>
                            {/* ----REFFERAL CODE---- */}
                            <div className={styles.form_field}>
                              <label htmlFor="referralCode" className={styles.register_form}>Referral Code</label>
                              <div className={styles.input_wrapper}>
                                <input
                                  type="text"
                                  placeholder="Referral Code"
                                  id="referralCode"
                                  name="referralCode"
                                  onChange={handleReferralCode}
                                  value={inputValue.referralCode}
                                  
                                  className={styles.input}
                                />
                              </div>
                            </div>
                            <button type="button" className={styles.create_account_button} onClick={handleSubmit} disabled={registerLoading}>
                              {registerLoading && <CircularProgress size={14} style={{marginRight: '5px'}}/>}
                              Create Account
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className={styles.right_inner}>
                    <div className={styles.existing_user}>
                      Already a user?
                      <Link to="/login">
                        <button type="link" className={styles.login_link}>
                          Sign In
                        </button>
                      </Link>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </Container>
          </div>       
        </>
      {/* )}     */}
    </>
  );
};
