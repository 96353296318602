import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import "./PublicChallenge.css"
import ContentLoader from 'react-content-loader';
import MarketplaceSkeleton from '../skeletons/MarketplaceSkeleton';
import { errorToast } from '../constants/ToastNotifications';
import { Avatar } from '@material-ui/core';
import moment from "moment-timezone";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import userOnline from '../images/User_Status_Online.svg';
import userOffline from '../images/User_Status_Offline.svg';
export const PublicChallenge = ({homepage}) => {
const [allchallenge,setAllChallenge] = useState([])
const [userdata,setuserdata] = useState([])
const [loading, setLoading] = useState(false);
const [userinfo, setUserInfo] = useState(false);
const data = JSON.parse(localStorage.getItem("nftuser"))
const navigate = useNavigate()

const getallchallenge = async()=>{
 try{
  setLoading(true);
  const res = await axios.post("/api/auth/getPublicChallengeData",{id:data?._id})
  // if(res){
  // const filtered =  res.data.filter((items,index)=>{
  //   return  items.category==="public"&&items.player_1_id !==data._id&&items.Accept==="pending"
  //   })
  // }
  setLoading(false);
  if(homepage.isOpen){
    console.log(res.data)
    setAllChallenge(res.data.slice(0, 3))
  } else {
    setAllChallenge(res.data)
  }
 
 } catch(error){
  errorToast("Something went wrong!")
 }
}

const getuserdata = async () => {
  try{
    setLoading(true);
    const res = await axios.post("/api/auth/getuserdata");
    setLoading(false);
    setuserdata(res.data)  
  } catch(error){
    errorToast("Something went wrong!")
  }
}

const goToPublicAccept = (item) => {
  if(data){
    navigate(`/publicRecieve/${item}`)
  } else {
    setUserInfo(true)
  }
}
const goToLogin = () => {
  navigate(`/login`)
}
const handleClose = () => {
  setUserInfo(false);
};
const setUserActiveStatus = (lastactivetime) => {
  if(lastactivetime){
    if((moment().diff(moment(lastactivetime),"minutes")) >= 60){
      return false;
    } else {
      return true
    }
  } else {
    return false;
  }
}
useEffect(()=>{
getallchallenge()
getuserdata()
},[])

const useStyles = makeStyles({
  ts: {
    color: "#ffffff",
    fontSize: `${window.innerWidth > 400 ? "15px" : "13px"}`,
    fontFamily: "Roboto,sans-serif",
    "&:hover": {
      color: "#ffffff",
    },
    "&:active": {
      color: "#ffffff",
    },
    "&:focus": {
      color: "#ffffff",
    },
  },

  tfund:{
    color: "#1DCADC",
  fontSize: `${window.innerWidth > 400 ? "18px" : "16px"}`,
  fontFamily: "Bungee",
  "&:hover": {
    color: "#1DCADC",
  },
  "&:active": {
    color: "#1DCADC",
  },
  "&:focus": {
    color: "#1DCADC",
  },
},
  selectDropdown: { color: "#fff", backgroundColor: "#0b0b27" },
  menuItem: {
    "&:hover": {
      backgroundColor: "#06001b",
    },
    select: {
      color: "red",
      background: "white",
    },
  },
});


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0b0b27",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#06001b",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#06001b",
  },
}));
const classes = useStyles();
  return (
 <div style = {!homepage.isOpen ? { height:"100vh"} : null} className='container public-chall-padding'>
 <div className={!homepage.isOpen ? "public-container":"home-public-container"}>
 {/* <table className='table table-striped'>
<thead>
<tr  className='border-none' style = {{color:"white"}}>
<th>#</th>
    <th>Username</th>
    <th>Twitch/Youtube</th>
    <th>Game of Choice</th>
    <th>Terms</th>
    <th>Accept Challenge</th>
    <th>Cards</th>
</tr>

</thead>
<tbody>
  {
    allchallenge.map((items,index)=>{
      console.log(items._id)
    return(
  <tr className="border-none" style = {{"cursor":"pointer",border:"none"}} onClick={()=>{navigate(`/publicRecieve/${items._id}`)}}>
<td>
<img style = {{width:"36px"}} src= {items.player_1[0].avatar}/>
</td>
  <td>
  {items.player_1[0].name}</td>
  <td>{items.player_1[0].link}</td>
  <td>{items.player_1[0].gamechoice}</td>
  <td>{items.player_1[0].text}</td>
  <td>
<button style = {{color:"white"}} className='table-hero-btn'>Accept Challenge</button>
    </td>
    <td>
    {items.player_1[0].images.map((item)=>{
      return <img src={item} alt='Card' height="150px" width="130px"/>
    })}
    </td>
  </tr>
    )
    })
  }
</tbody>

 </table> */}
 <TableContainer component={Paper} className={allchallenge?.length > 3 ? 'set-table-height' : 'table-height-auto'} id="style-2">
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <StyledTableHeader>
                      <TableCell className={classes.ts} align="center">Ready Now!</TableCell>
                      <TableCell className={classes.ts} align="center">Username</TableCell>
                      <TableCell className={classes.ts}>Twitch/Youtube</TableCell>
                      <TableCell className={classes.ts} align="center">Game of Choice</TableCell>
                      <TableCell className={classes.ts} align="center">Game Network</TableCell>
                      <TableCell className={classes.ts} align="center">Terms</TableCell>
                      <TableCell className={classes.ts} align="center">Buy In</TableCell>
                      <TableCell className={classes.ts} align="center">Payouts</TableCell>
                      <TableCell className={classes.ts} align="center">Accept Challenge</TableCell>
                      <TableCell className={classes.ts} align="center">Challenge Date</TableCell>
                      <TableCell className={classes.ts} align="center">Challenge No</TableCell>
                      {/* <TableCell className={classes.ts} align="center">Cards</TableCell> */}
                      
                    </StyledTableHeader>
                  </TableHead>
                  {loading ? 
                  <TableBody><MarketplaceSkeleton/></TableBody> : 
                  allchallenge?.length > 0 ?
                  <TableBody>
                    {allchallenge
                      .map((items, index) => (
                        <StyledTableRow
                          key={items.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell className={classes.ts} align="center">
                           
                            {setUserActiveStatus(items?.player_1[0]?.userId?.last_active) ? <img loading="lazy" src={userOnline} alt="venmo" className="online-icon"/> : <img loading="lazy" src={userOffline} alt="venmo" className="online-icon"/>}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          <Avatar  src= {`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${items.player_1[0].avatar}`} className="avatar-cell"/>                          
                          {items.player_1[0].name}
                          </TableCell>
                          <TableCell className={classes.ts} align="left">
                          {items.player_1[0].link}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.player_1[0].gamechoice}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.player_1[0].gamenetwork}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.player_1[0].text}
                          </TableCell>
                          <TableCell className={classes.tfund} align="center">
                          ${items.playerwager?.toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.tfund} align="center">
                          ${((items.playerwager)*2)?.toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {data?._id != items?.player_1[0]?.userId?._id ? <button style = {{color:"white"}} className='table-hero-btn' onClick={()=> goToPublicAccept(items._id)}>Accept Challenge</button> : "-" }
                          </TableCell> 
                          <TableCell className={classes.ts} align="center">
                          {items?.challenge_created ?<>{moment(items?.challenge_created).tz(moment.tz.guess()).format("MM/DD/YYYY h:mm A")}</> : <>---</> }
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.challenge_number}
                          </TableCell>
                          {/* <TableCell className={classes.ts} align="center">
                          {items.player_1[0].images.map((item)=>{
                            return <img src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/cards/${item}`} alt='Card' height="150px" width="130px"/>
                          })}
                          </TableCell>  */}
                        </StyledTableRow>
                      ))}
                  </TableBody> : null}
                </Table>
              </TableContainer>
              {allchallenge?.length===0 &&!loading && homepage.isOpen && <div className="no-records-found-home">No Live Challenges are Available. <br/> <Link to="/ChallengeSomeone" className="btn btn-primary primary-btn-linear-bg public-chal-btn-size">Post your own public challenge!</Link></div>}
              {allchallenge?.length===0 &&!loading && !homepage.isOpen && <div className="no-records-found">No Live Challenges are Available.</div>}
    </div>
    {userinfo  &&<div className="modal challenge-someone-modal">
                     <div className="modal-content public-modal-content">
                     <Modal.Header className="challengesomeone-header">
                     <button onClick={()=>handleClose()} className="modal-button challengesomeone-cancel-btn">&times;</button>
                     <Modal.Title>Accept Challange</Modal.Title>
                     </Modal.Header>
                     <Modal.Body>
                       <p>Accepting this challenge requires logging in. Please press the OK button to be redirected to the login page.</p>
                       </Modal.Body>
                       <Modal.Footer className='public-chall'>
                       <button className="public-chal-accept-btn" onClick={ () => goToLogin()}>
                            ok
                        </button>
                        <button className="public-chal-accept-btn" onClick={handleClose}>
                            cancel
                        </button>
                       </Modal.Footer>
                     </div>
                   </div>}
 </div>
  )
}
